<template>
  <div class="resource">
    <div class="resource_center">
      <header>
        <p>资源审核管理</p>
      </header>
      <nav>
        <el-form ref="resourceRef" :model="resourceForm" :inline="true">
          <el-form-item label="资源名称" prop="courseName">
            <el-input v-model="resourceForm.courseName" size="mini" clearable></el-input>
          </el-form-item>
          <el-form-item label="资源类型" prop="resourceType">
            <el-select v-model="resourceForm.resourceType" size="mini" clearable>
              <el-option v-for="item in restypedata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学科类型" prop="subjectTypeId">
            <el-select v-model="resourceForm.subjectTypeId" @change="changeshuject" size="mini" clearable>
              <el-option v-for="item in subjectypedata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学科" prop="subjectId">
            <el-select v-model="resourceForm.subjectId" size="mini" clearable>
              <el-option v-for="item in subjectdata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学段" prop="periodId">
            <el-select v-model="resourceForm.periodId" @change="changeperiod" size="mini" clearable>
              <el-option v-for="item in perioddata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="年级" prop="gradeId">
            <el-select v-model="resourceForm.gradeId" size="mini" clearable>
              <el-option v-for="item in gradedata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="提交审核时间">
            <el-form-item prop="auditStartTime" style="width:135px">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="resourceForm.auditStartTime"
                style="width: 100%;"
                size="mini"
                clearable
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            <el-form-item prop="auditEndTime" style="width:135px">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="resourceForm.auditEndTime"
                style="width: 100%;"
                size="mini"
                clearable
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-form-item>
          <el-form-item label="审核状态" prop="audit">
            <el-select v-model="resourceForm.audit" placeholder="请选择" size="mini" clearable>
              <el-option v-for="item in auditdata" :key="item.id" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item>
            <el-button type="primary" @click="querylist">查询</el-button>
            <el-button type="primary" @click="resetfrom('resourceRef')">重置</el-button>
          </el-form-item>-->
        </el-form>
        <div class="nav_btns">
          <el-button type="primary" @click="querylist" size="mini">查询</el-button>
          <el-button @click="resetfrom('resourceRef')" size="mini">重置</el-button>
        </div>
      </nav>
      <aside>
        <el-button type="primary" @click="auditagree(null)" size="mini">批量审核通过</el-button>
        <el-button type="danger" @click="auditdisagree(null)" size="mini">批量审核不通过</el-button>
      </aside>
      <main>
        <el-table :data="resTabDatas" stripe style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="50" align="center" fixed="left"></el-table-column>
          <el-table-column type="index" width="50" align="center" fixed="left" label="序号" :index="computeTableIndex"></el-table-column>
          <el-table-column prop="resourceTitle" label="资源名称" width="150" fixed="left" show-overflow-tooltip></el-table-column>
          <el-table-column prop="resourceType" label="资源类型" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="periodName" label="学段" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="gradeName" label="年级" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="subjectType" label="学科类型" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="subjectName" label="学科" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="createUser" label="创建者" width="150"></el-table-column>
          <el-table-column prop="auditStatus" label="审核状态" width="120"></el-table-column>
          <!-- <el-table-column prop="lastAuditMessage" label="审核意见" width="300" show-overflow-tooltip></el-table-column> -->
          <el-table-column prop="createTime" label="资源创建时间" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column prop="auditTime" label="提交审核时间" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column fixed="right" label="操作" width="300">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                @click="auditagree(scope.row.id)"
                :disabled="scope.row.auditStatus=='审核通过'||scope.row.auditStatus=='审核不通过'"
              >审核通过</el-button>
              <el-button type="text" size="mini" @click="viewdata(scope.row,1)" v-if="scope.row.auditStatus=='审核通过'">查看资源</el-button>
              <el-button type="text" size="mini" @click="viewdata(scope.row,0)" v-else>查看资源</el-button>
              <el-button type="text" size="mini" @click="recorddata(scope.row.auditMessage)">审核记录</el-button>
              <el-button type="text" size="mini" @click="auditdisagree(scope.row.id)" style="color: #EB5757;">审核不通过</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="curpage"
          :page-sizes="[10, 20, 30, 40 ,50]"
          :page-size="pageSize"
          layout="total,sizes, prev, pager, next"
          :total="total"
        ></el-pagination>
      </main>
      <el-dialog
        title="审核不通过"
        :visible.sync="auditDialog"
        width="30%"
        top="5%"
        class="checkTabs"
        :closeOnClickModal="false"
        :showClose="false"
        :closeOnPressEscape="false"
      >
        <el-input v-model="audittext" type="textarea" :rows="5"></el-input>
        <div class="butognguo">
          <el-button type="danger" @click="sureDialog" size="mini">审核不通过</el-button>
          <el-button @click="cancleDialog" size="mini">取消</el-button>
        </div>
      </el-dialog>
      <el-dialog title="审核记录" :visible.sync="recordDialog" width="50%">
        <el-table :data="recordData" style="width: 100%">
          <el-table-column prop="userName" label="审核人" width="240"></el-table-column>
          <el-table-column prop="message" label="审核意见"></el-table-column>
          <el-table-column prop="date" label="审核时间" width="240"></el-table-column>
        </el-table>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 课程资源审核列表
      resourceForm: {
        courseName: null,
        resourceType: null,
        subjectTypeId: null,
        subjectId: null,
        periodId: null,
        gradeId: null,
        auditStartTime: null,
        auditEndTime: null,
        audit: null,
      },
      //

      // 资源类型
      restypedata: null,
      //学科类型
      subjectypedata: null,
      // 学科
      subjectdata: null,
      // 学段
      perioddata: null,
      // 年级
      gradedata: null,
      //审核状态
      auditdata: null,

      // 表格数据
      resTabData: null,
      // 多选的数据
      multipleSelection: null,

      // 分页
      curpage: 1, //当前页数
      pageSize: 10, //当前条数
      total: 100, //总数

      //审核意见弹框
      auditDialog: false,
      dialogId: null,
      audittext: null,
      //审核记录
      recordDialog: false,
      recordData: null,
    };
  },
  created() {
    //
    this.queryResourceType(); // 获取资源类型
    this.querySubjectType(); //获取课程类型
    this.querySubject(0); //获取学科信息
    this.queryPeriod(); //获取学段
    this.queryGrade(0); //获取年级信息
    this.querystatus(); //获取审核状态
    // 查获取资源列表
    this.queryAuditResource();
  },
  methods: {
    // 查询资源列表
    async queryAuditResource() {
      const { data, status } = await this.$Http.queryAuditResource({
        // courseName: courseName, //资源名称
        // resourceType: resourceType, //资源类型
        // subjectTypeId: subjectTypeId, //课程类型ID
        // subjectId: subjectId, //学科ID
        // periodId: periodId, //学段ID
        // gradeId: gradeId, //年级ID
        // auditStartTime: auditStartTime, //审核开始时间
        // auditEndTime:auditEndTime,//审核结束时间
        // audit: audit, //审核状态
        ...this.resourceForm,
        pageNo: this.curpage, //页码
        pageSize: this.pageSize, //每页数量
      });
      if (status == 200) {
        console.log(data, status, "获取资源列表");
        this.resTabData = data;
      }
    },
    // 获取资源类型
    async queryResourceType() {
      await this.$Http.queryResourceType().then((res) => {
        console.log(res, "获取资源类型");
        if (res.status == 200) {
          this.restypedata = res.data;
        }
      });
    },
    // 获取课程类型
    async querySubjectType() {
      await this.$Http.querySubjectType().then((res) => {
        console.log(res, "获取课程类型");
        if (res.status == 200) {
          this.subjectypedata = res.data;
        }
      });
    },
    // 获取学科信息
    async querySubject(id) {
      console.log(id);
      let ids = [];
      if (id !== 0) {
        ids.push(id);
      }
      const { data, status } = await this.$Http.querySubject({
        idList: ids,
      });
      if (status == 200) {
        console.log(data, status, "获取学科");
        this.subjectdata = data;
      }
    },
    // 获取学段
    async queryPeriod() {
      await this.$Http.queryPeriod().then((res) => {
        console.log(res, "获取学段");
        if (res.status == 200) {
          this.perioddata = res.data;
        }
      });
    },
    // 获取年级信息
    async queryGrade(id) {
      console.log(id);
      let ids = [];
      if (id !== 0) {
        ids.push(id);
      }
      const { data, status } = await this.$Http.queryGrade({
        idList: ids,
      });
      if (status == 200) {
        console.log(data, status, "获取年级信息");
        this.gradedata = data;
      }
    },
    //审核状态
    async querystatus() {
      // console.log("获取审核状态");
      let aa = [
        {
          id: 0,
          name: "未提交审核",
          value: "0",
        },
        {
          id: 1,
          name: "审核未通过",
          value: "1",
        },
        {
          id: 2,
          name: "待审核",
          value: "2",
        },
        {
          id: 3,
          name: "审核已通过",
          value: "3",
        },
      ];
      this.auditdata = aa;
    },
    // 学科类型的值得变化
    changeshuject(val) {
      this.resourceForm.subjectId = null;
      if (!val) {
        this.querySubject(0);
      } else {
        this.querySubject(val);
      }
    },
    // 学段的值得变化
    changeperiod(val) {
      this.resourceForm.gradeId = null;
      if (!val) {
        this.queryGrade(0);
      } else {
        this.queryGrade(val);
      }
    },

    // 查询
    querylist() {
      this.curpage = 1;
      this.queryAuditResource();
    },
    // 重置
    resetfrom(formName) {
      console.log(this.$refs, "重置");
      this.$refs[formName].resetFields();
      this.curpage = 1;
      this.queryAuditResource();
      this.querySubject(0); //获取学科信息
      this.queryGrade(0); //获取年级信息
    },

    // 审核通过
    async auditagree(rowId) {
      let ids = [];
      if (rowId == null) {
        if (this.multipleSelection == null) {
          this.$message.warning("请选择要批量审核的资源");
          return;
        } else {
          for (let x = 0; x < this.multipleSelection.length; x++) {
            ids.push(this.multipleSelection[x].id);
          }
        }
      } else {
        ids.push(rowId);
      }
      this.$confirm("确认审核通过？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data, status } = await this.$Http.auditCourse({
            courseIdList: ids, //资源id集合
            auditType: 1, //	审核类型 0-审核不通过 1-审核通过
            // message: message, //审核意见
          });
          if (status == 200) {
            console.log(data, status, "审核通过");
            this.$message.success("审核通过");
            this.queryAuditResource();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 取消审核意见
    cancleDialog() {
      this.$confirm("是否取消审核不通过", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.auditDialog = false;
          this.audittext = null;
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 确认审核意见
    sureDialog() {
      if (this.audittext == null) {
        this.$message.warning("审核意见不能为空");
      } else {
        this.$confirm("确定审核不通过？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.auditDialog = false;
            this.auditdisCourse();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
    },
    // 审核不通过
    async auditdisagree(rowId) {
      let ids = [];
      if (rowId == null) {
        if (
          this.multipleSelection == null ||
          this.multipleSelection.length == 0
        ) {
          this.$message.warning("请选择要批量审核的资源");
          return;
        } else {
          for (let x = 0; x < this.multipleSelection.length; x++) {
            ids.push(this.multipleSelection[x].id);
          }
        }
      } else {
        ids.push(rowId);
      }
      this.dialogId = ids;
      this.auditDialog = true;
    },
    //
    // 审核不通过提交
    async auditdisCourse() {
      const { data, status } = await this.$Http.auditCourse({
        courseIdList: this.dialogId, //资源id集合
        auditType: 0, //	审核类型 0-审核不通过 1-审核通过
        message: this.audittext, //审核意见
      });
      if (status == 200) {
        console.log(data, status, "审核不通过");
        this.queryAuditResource();
        this.audittext == null;
      }
    },
    // 查看资源
    viewdata(row, num) {
      console.log(row);
      this.$router.push({
        path: "/resourcesinfo",
        query: {
          id: row.id,
          cardsite: num,
        },
      });
    },
    // 审核记录
    recorddata(row) {
      console.log(row);
      this.recordData = row;
      this.recordDialog = true;
    },
    // 批量删除
    somedel() {
      console.log(this.multipleSelection, "批量删除");
      this.queryAuditResource();
    },
    //
    // 多选
    handleSelectionChange(val) {
      console.log(val, "xuzhogn");
      this.multipleSelection = val;
    },
    //监听index
    computeTableIndex(index) {
      // console.log(index, this.queryInfo.pagenum, this.queryInfo.pagesize, 'e')
      return (this.curpage - 1) * this.pageSize + index + 1;
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.queryAuditResource();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.curpage = val;
      this.queryAuditResource();
    },
  },
  watch: {},
  computed: {
    resTabDatas() {
      let a = this.resTabData;
      if (a !== null) {
        let aa = this.resTabData.list;
        this.total = this.resTabData.total;
        if (aa[0].periodName.join === undefined) {
          return aa;
        }
        for (let x = 0; x < aa.length; x++) {
          aa[x].periodName = aa[x].periodName.join("/");
          aa[x].gradeName = aa[x].gradeName.join("/");
          aa[x].subjectName = aa[x].subjectName.join("/");
          aa[x].subjectType = aa[x].subjectType.join("/");
        }
        return aa;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.resource {
  .resource_center {
    header {
      height: 70px;
      padding: 0 20px;
      background-color: #fff;
      border-bottom: 1px solid #ebebeb;
      p {
        // width: 108px;
        // height: 24px;
        height: 70px;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 600;
        color: #141516;
        line-height: 70px;
      }
    }
    nav {
      background-color: #fff;
      padding: 15px;
      // .el-form-item{
      //   width: 230px;
      // }
      .el-input {
        width: 100px;
      }
      .el-select {
        width: 116px;
      }
      .nav_btns {
        display: flex;
        justify-content: flex-end;
      }
    }
    aside {
      margin-top: 10px;
      background-color: #fff;
      padding: 20px;
    }
    main {
      padding: 0 20px;
      background-color: #fff;
      .el-pagination {
        display: flex;
        justify-content: center;
        padding: 20px 0;
      }
    }
    .checkTabs {
      .butognguo {
        margin-top: 23px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
</style>